import axios from 'axios';
import Moment from 'moment';
import Promise from 'promise';

import localStorage from '../../helpers/localStorage';
import { open as openUrl } from '../../helpers/URLHelper';
import { parseTokenResponseData } from '../helpers/session';
import { getSubscriptionUrl } from '../helpers/subscription';
import { requestNewTokenFromEngine } from '../services/auth';
import { error as errorAlert } from '../state/notifications/actions';
import { createUserSession, destroyUserSession } from '../state/session/actions';
import { expireSubscription } from '../state/subscription/actions';

function getSubscriptionTypeFromError(errorCode) {
  let type = '';
  if (errorCode === 'SUBSCRIPTION_EXPIRED') {
    type = 'customer';
  } else if (errorCode === 'TRIAL_EXPIRED') {
    type = 'trial';
  }
  return type;
}

function getUserSession() {
  return localStorage.getItem('session');
}

function tokenExpired(createdAt, expiresIn) {
  const currentTime = Moment(Moment().add(10, 'seconds').format());
  const expirationTime = Moment(Moment.unix(createdAt).add(expiresIn, 'seconds').format());
  return currentTime.isAfter(expirationTime);
}

function accessToken() {
  const session = getUserSession();
  return session?.accessToken;
}

const service = axios.create({
  baseURL: process.env.ENGINE_API_URL,
  headers: {
    'Spiro-API-Version': process.env.ENGINE_VERSION,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

let store = null;

const EngineService = {
  config(options = {}) {
    store = options.store || store;
  },

  get(endpoint, params = {}, additionalHeaders) {
    return service
      .get(endpoint, {
        params,
        headers: {
          Authorization: `Bearer ${accessToken()}`,
          ...additionalHeaders,
        },
      })
      .then((r) => r.data);
  },

  post(endpoint, data, params = {}, additionalHeaders, options = null) {
    return service
      .post(endpoint, data, {
        params,
        ...options,
        headers: {
          Authorization: `Bearer ${accessToken()}`,
          ...additionalHeaders,
        },
      })
      .then((r) => r.data);
  },

  patch(endpoint, data, params = {}, additionalHeaders) {
    return service
      .patch(endpoint, data, {
        params,
        headers: {
          Authorization: `Bearer ${accessToken()}`,
          ...additionalHeaders,
        },
      })
      .then((r) => r.data);
  },

  put(endpoint, data, params = {}, additionalHeaders) {
    return service
      .put(endpoint, data, {
        params,
        headers: {
          Authorization: `Bearer ${accessToken()}`,
          ...additionalHeaders,
        },
      })
      .then((r) => r.data);
  },

  delete(endpoint, params = {}, additionalHeaders) {
    return service
      .delete(endpoint, {
        params,
        headers: {
          Authorization: `Bearer ${accessToken()}`,
          ...additionalHeaders,
        },
      })
      .then((r) => r.data);
  },
};

service.interceptors.request.use(
  (conf) => {
    const originalRequest = conf;
    const { createdAt, expiresIn, refreshToken } = getUserSession();

    if (tokenExpired(createdAt, expiresIn)) {
      return requestNewTokenFromEngine({
        refresh_token: refreshToken,
        client_id: process.env.APP_ID,
        client_secret: process.env.APP_SECRET,
        grant_type: 'refresh_token',
      })
        .then((data) => {
          store.dispatch(createUserSession(parseTokenResponseData(data)));
          originalRequest.headers.Authorization = `Bearer ${data.access_token}`;
          return Promise.resolve(originalRequest);
        })
        .catch((e) => {
          if (e.status === 401) {
            store.dispatch(destroyUserSession());
          }
        });
    }
    return conf;
  },
  (error) => Promise.reject(error)
);

service.interceptors.response.use(
  (response) => response,
  (err) => {
    if (!store) return Promise.reject(err);

    const e = err.response;

    const { userEmail } = getUserSession();

    if (e.status === 401) {
      store.dispatch(errorAlert(e.data.message));
      store.dispatch(destroyUserSession());
    }
    if (e.status === 402) {
      store.dispatch(
        expireSubscription({
          customerStatus: getSubscriptionTypeFromError(e.data.errorCode),
        })
      );
      openUrl(getSubscriptionUrl(userEmail), { target: '_self' });
      store.dispatch(destroyUserSession());
    }
    if (e.status === 422 && e.data.message === 'Invalid Version') {
      window.location.reload();
    }
    return Promise.reject(e);
  }
);

export default EngineService;
